import cn from 'classnames';
import Link from 'next/link';
import { HeadingText } from '../../atoms/HeadingText';

export interface ArticleCardCompactProps {
	href: string;
	title: string;
	category?: string;
	testId?: string;
}

const ArticleCardCompact = (props: ArticleCardCompactProps) => {
	const { category, href, testId = 'ArticleCardCompact', title } = props;

	const isAirConditioners = category === 'Air Conditioners';
	const isAirQuality = category === 'Air Quality';
	const isHeatPumps = category === 'Heat Pumps';
	const isFurnaces = category === 'Furnaces';
	const isTroubleshooting = category === 'Troubleshooting';
	const isHumidity = category === 'Humidity';
	const isDefault =
		!isAirConditioners &&
		!isAirQuality &&
		!isHeatPumps &&
		!isFurnaces &&
		!isTroubleshooting &&
		!isHumidity;

	return (
		<Link
			href={href}
			className={cn(
				'block h-full rounded-3xl p-6 shadow-xl transition-all duration-500 hover:cursor-pointer',
				{
					'shadow-sky-600/15 hover:shadow-xl hover:shadow-sky-600/25':
						isAirConditioners,
					'shadow-lavender-600/15 hover:shadow-xl hover:shadow-lavender-600/25':
						isAirQuality,
					'shadow-pink-600/15 hover:shadow-xl hover:shadow-pink-600/25':
						isHeatPumps,
					'shadow-gray-200/15 hover:shadow-xl hover:shadow-gray-200/25':
						isDefault,
					'shadow-red-600/15 hover:shadow-xl hover:shadow-red-600/25':
						isFurnaces,
					'shadow-lime-600/15 hover:shadow-xl hover:shadow-lime-600/25':
						isHumidity,
					'shadow-amber-600/15 hover:shadow-xl hover:shadow-amber-600/25':
						isTroubleshooting,
				},
			)}
			data-testid={testId}
		>
			<article className={'flex h-full flex-col justify-between'}>
				<HeadingText tag="h2" size="h4" className="!font-medium">
					{title}
				</HeadingText>

				{category && (
					<div
						className={cn('mt-10 max-w-fit rounded-md px-2 py-1 text-sm', {
							'bg-gray-100 text-gray-600': isDefault,
							'bg-sky-100 text-sky-600': isAirConditioners,
							'bg-lavender-100 text-lavender-600': isAirQuality,
							'bg-pink-100 text-pink-600': isHeatPumps,
							'bg-red-100 text-red-600': isFurnaces,
							'bg-amber-100 text-amber-600': isTroubleshooting,
							'bg-lime-100 text-lime-600': isHumidity,
						})}
					>
						<div
							className={cn({
								'text-sky-600': isAirConditioners,
								'text-pink-600': isHeatPumps,
								'text-red-600': isFurnaces,
								'text-amber-600': isTroubleshooting,
								'text-lime-600': isHumidity,
								'text-gray-600': isDefault,
								'text-lavender-600': isAirQuality,
							})}
						>
							{category}
						</div>
					</div>
				)}
			</article>
		</Link>
	);
};

export default ArticleCardCompact;
