import React from 'react';
import classNames from 'classnames';

const StrapiFeaturedArticlesSkeleton = () => {
	return (
		<div
			className={classNames(
				`grid-layout h-124 border-t border-black/10 pb-13 pt-8 sm:border-none sm:pb-23
				sm:pt-0`,
			)}
		>
			<div
				className="col-span-4 grid gap-y-10 sm:col-start-2 sm:col-end-8 sm:grid-cols-6 sm:gap-8
					lg:col-start-1 lg:col-end-11 lg:grid-cols-4 2xl:col-end-13"
			></div>
		</div>
	);
};

export default StrapiFeaturedArticlesSkeleton;
